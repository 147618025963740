@font-face {
  font-family: 'volvooffers-icons';
  src:  url('../fonts/volvooffers-icons.eot?3gzent');
  src:  url('../fonts/volvooffers-icons.eot?3gzent#iefix') format('embedded-opentype'),
    url('../fonts/volvooffers-icons.ttf?3gzent') format('truetype'),
    url('../fonts/volvooffers-icons.woff?3gzent') format('woff'),
    url('../fonts/volvooffers-icons.svg?3gzent#volvooffers-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="brand-icon-"], [class*=" brand-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'volvooffers-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brand-icon-medium {
  font-size: 20px;
  line-height: 1;
}

.brand-icon-back:before {
  content: '\e901';
}
.brand-icon-car:before {
  content: '\e902';
}
.brand-icon-close:before {
  content: '\e903';
}
.brand-icon-colapse:before {
  content: '\e904';
}
.brand-icon-contact-details:before {
  content: '\e905';
}
.brand-icon-date:before {
  content: '\e906';
}
.brand-icon-dealer:before {
  content: '\e907';
}
.brand-icon-directions:before {
  content: '\e908';
}
.brand-icon-down-arrow:before {
  content: '\e909';
}
.brand-icon-email:before {
  content: '\e90a';
}
.brand-icon-expand:before {
  content: '\e90b';
}
.brand-icon-filter:before {
  content: '\e90c';
}
.brand-icon-fuel:before {
  content: '\e90d';
}
.brand-icon-heart-fill:before {
  content: '\e90e';
}
.brand-icon-heart:before {
  content: '\e90f';
}
.brand-icon-info:before {
  content: '\e910';
}
.brand-icon-left-nav-arrow:before {
  content: '\e911';
}
.brand-icon-menu:before {
  content: '\e912';
}
.brand-icon-more-menu-fill:before {
  content: '\e913';
}
.brand-icon-more-menu:before {
  content: '\e914';
}
.brand-icon-offers:before {
  content: '\e915';
}
.brand-icon-pdf:before {
  content: '\e916';
}
.brand-icon-right-link-arrow:before {
  content: '\e917';
}
.brand-icon-right-nav-arrow:before {
  content: '\e918';
}
.brand-icon-search:before {
  content: '\e919';
}
.brand-icon-share:before {
  content: '\e91a';
}
.brand-icon-sort:before {
  content: '\e91b';
}
.brand-icon-test-drive:before {
  content: '\e91c';
}
.brand-icon-traction:before {
  content: '\e91d';
}
.brand-icon-transmission:before {
  content: '\e91e';
}
.brand-icon-up-arrow:before {
  content: '\e91f';
}
.brand-icon-user:before {
  content: '\e920';
}
.brand-icon-remove:before {
  content: '\e900';
}
