@import "fonts.css";
@import "brand-icon-font.css";

body {
    /* only disable pull-to-refresh but allow swipe navigations */
    overscroll-behavior-y: contain;
    overflow-x: hidden;
    min-width: 340px;
    font-family: "Volvo Novum", Arial, sans-serif;
}

.preloader-skeleton {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.swiper-lazy {
    opacity: 0
}

.swiper-lazy-loaded, .lazy-loaded {
    opacity: 1;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-delay: 0ms;
    animation-delay: 0ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-name:reveal-animation;
    animation-name:reveal-animation;
}

@-webkit-keyframes reveal-animation {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-moz-keyframes reveal-animation {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-o-keyframes reveal-animation {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes reveal-animation {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
