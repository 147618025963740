/*@font-face {*/
/*    font-display: swap;*/
/*    font-weight: 200;*/
/*    src: url('../fonts/volvo-novum-light.woff2') format('woff2'), url('../fonts/volvo-novum-light.woff') format('woff');*/
/*    font-family: "Volvo Novum"*/
/*}*/

/*@font-face {*/
/*    font-display: swap;*/
/*    font-weight: 200;*/
/*    font-style: italic;*/
/*    src: url('../fonts/volvo-novum-light-italic.woff2') format('woff2'), url('../fonts/volvo-novum-light-italic.woff') format('woff');*/
/*    font-family: "Volvo Novum"*/
/*}*/

@font-face {
    font-display: swap;
    font-weight: 300;
    /*src: url('../fonts/volvo-novum-semi-light.woff2') format('woff2'), url('../fonts/volvo-novum-semi-light.woff') format('woff');*/
    src: url('../fonts/volvo-novum-light.woff2') format('woff2'), url('../fonts/volvo-novum-light.woff') format('woff');
    font-family: "Volvo Novum"
}

/*@font-face {*/
/*    font-display: swap;*/
/*    font-weight: 300;*/
/*    font-style: italic;*/
/*    !*src: url('../fonts/volvo-novum-semi-light-italic.woff2') format('woff2'), url('../fonts/volvo-novum-semi-light-italic.woff') format('woff');*!*/
/*    src: url('../fonts/volvo-novum-light-italic.woff2') format('woff2'), url('../fonts/volvo-novum-light-italic.woff') format('woff');*/
/*    font-family: "Volvo Novum"*/
/*}*/

@font-face {
    font-display: swap;
    font-weight: 400;
    src: url('../fonts/volvo-novum-regular.woff2') format('woff2'), url('../fonts/volvo-novum-regular.woff') format('woff');
    font-family: "Volvo Novum"
}

/*@font-face {*/
/*    font-display: swap;*/
/*    font-weight: 400;*/
/*    font-style: italic;*/
/*    src: url('../fonts/volvo-novum-italic.woff2') format('woff2'), url('../fonts/volvo-novum-italic.woff') format('woff');*/
/*    font-family: "Volvo Novum"*/
/*}*/

@font-face {
    font-display: swap;
    font-weight: 500;
    src: url('../fonts/volvo-novum-medium.woff2') format('woff2'), url('../fonts/volvo-novum-medium.woff') format('woff');
    font-family: "Volvo Novum"
}

/*@font-face {*/
/*    font-display: swap;*/
/*    font-weight: 500;*/
/*    font-style: italic;*/
/*    src: url('../fonts/volvo-novum-medium-italic.woff2') format('woff2'), url('../fonts/volvo-novum-medium-italic.woff') format('woff');*/
/*    font-family: "Volvo Novum"*/
/*}*/

/*@font-face {*/
/*    font-display: swap;*/
/*    font-weight: 700;*/
/*    src: url('../fonts/volvo-novum-bold.woff2') format('woff2'), url('../fonts/volvo-novum-bold.woff') format('woff');*/
/*    font-family: "Volvo Novum"*/
/*}*/

/*@font-face {*/
/*    font-display: swap;*/
/*    font-weight: 700;*/
/*    font-style: italic;*/
/*    src: url('../fonts/volvo-novum-bold-italic.woff2') format('woff2'), url('../fonts/volvo-novum-bold-italic.woff') format('woff');*/
/*    font-family: "Volvo Novum"*/
/*}*/

@font-face {
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/volvo-broad.ttf') format('ttf'), url('../fonts/volvo-broad.woff') format('woff');
    font-family: "Volvo Broad"
}
